var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AlertTriangle, List } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import Select from "react-select";
import "./WaitingForEnvironment.css";
import "../styles/typography.css";
import { Badge } from "../Badges";
import ShipyardBoat from "../icons/ShipyardBoat";
import { fetchApplicationAsOauth, parseOrThrowRequest, post } from "../requests";
import { ApplicationBranchHighlight } from "../ResponsiveTextHighlight";
import { readableBuildFailure } from "../utils/build";
import { Status } from "./types";
var Logo = function (_a) {
    var apiUrl = _a.apiUrl;
    return (React.createElement("img", { src: "".concat(apiUrl, "/static/imgs/logo-icon-white.png"), style: { height: "45px", objectFit: "cover" } }));
};
var SiblingDropdown = function (_a) {
    var siblings = _a.siblings, originType = _a.originType;
    var options = siblings.map(function (env) { return ({
        value: env.active_url_with_route,
        label: originType === "project" ? env.branch : env.display_name,
        isDisabled: !env.active_url_with_route,
    }); });
    var handleSiblingChoice = function (option) {
        if (option)
            window.location.href = option.value;
    };
    if (siblings.length === 0)
        return null;
    return (React.createElement(Select, { onChange: handleSiblingChoice, options: options, placeholder: "Switch Environment", styles: {
            control: function (provided) { return (__assign(__assign({}, provided), { background: "#34393E" })); },
            placeholder: function (provided) { return (__assign(__assign({}, provided), { color: "var(--white)" })); },
            menuList: function (provided) { return (__assign(__assign({}, provided), { background: "#34393E" })); },
            option: function (provided, state) { return (__assign(__assign({}, provided), { background: "#34393E", color: state.isDisabled ? "gray" : "var(--white)" })); },
        } }));
};
/** Loading/progress bar to loosely indicate how far along a build is. */
var WaitingInfoProgress = function (_a) {
    var status = _a.status;
    var progress = (function () {
        switch (status) {
            case Status.BUILDING:
                return 25;
            case Status.DEPLOYING:
                return 50;
            case Status.STARTING:
                return 75;
            case Status.RUNNING:
                return 100;
            default:
                return 100;
        }
    })();
    var successIcon = (React.createElement("span", { className: "icon-circle success-circle" },
        React.createElement("i", { className: "fa fa-check" })));
    var loadingIcon = React.createElement("span", { className: "icon-circle in-progress-circle" });
    var pendingIcon = React.createElement("span", { className: "icon-circle pending-circle" });
    var firstIcon = progress <= 25 ? loadingIcon : successIcon;
    var secondIcon = progress < 50 ? pendingIcon : progress === 50 ? loadingIcon : successIcon;
    var thirdIcon = progress < 75 ? pendingIcon : progress === 75 ? loadingIcon : successIcon;
    return (React.createElement("div", { id: "waiting-info-snippet", className: "mt-5", "data-cy": "waiting-info-snippet" },
        React.createElement(ProgressBar, { striped: true, now: progress }),
        React.createElement("div", { className: "d-flex mt-3" },
            React.createElement("span", { className: "waiting-info-loading-status" },
                firstIcon,
                "\u00A0\u00A01. Building"),
            React.createElement("span", { className: "waiting-info-loading-status" },
                secondIcon,
                "\u00A0\u00A02. Deploying"),
            React.createElement("span", { className: "waiting-info-loading-status" },
                thirdIcon,
                "\u00A0\u00A03. Starting"))));
};
var WaitingInfoSnippet = function (_a) {
    var status = _a.status, failureReason = _a.failureReason, isLoggedIn = _a.isLoggedIn;
    var title = status === Status.FAILED
        ? "Error: ".concat(isLoggedIn ? readableBuildFailure(failureReason) : "failed")
        : "The environment is ".concat(status === Status.CANCELED ? "canceled" : "stopped");
    var statusBadge;
    if (status === Status.FAILED) {
        statusBadge = (React.createElement(Badge, { bgColor: "danger", textColor: "light" }, "Failed"));
    }
    else if (status === Status.DRYDOCK_LIMIT) {
        statusBadge = (React.createElement(Badge, { bgColor: "warning", textColor: "dark" }, "Out of Drydocks"));
    }
    var subtitle = "";
    if (status === Status.DRYDOCK_LIMIT)
        subtitle = "To start this environment, add more dry docks or stop another environment first.";
    return (React.createElement("div", { id: "waiting-info-snippet", "data-cy": "waiting-info-snippet" },
        React.createElement("div", { className: "d-flex font-weight-bold my-1 flex-gap-8px" },
            title,
            statusBadge),
        subtitle));
};
var WaitingButtons = function (props) {
    var status = props.status, isLoggedIn = props.isLoggedIn, showRestart = props.showRestart;
    var showReloadButton = status === Status.RUNNING;
    var reloadButton = (React.createElement(Button, { href: "." },
        React.createElement("i", { className: "fa fa-refresh" }),
        "\u00A0\u00A0Reload Page"));
    var showDashButton = isLoggedIn && [Status.FAILED, Status.CANCELED, Status.DRYDOCK_LIMIT].includes(status);
    var dashboadButton = (React.createElement(Button, { variant: "primary", href: "".concat(props.apiUrl, "/org/").concat(props.orgName), target: "_blank" },
        React.createElement(ShipyardBoat, null),
        "\u00A0\u00A0Go to Dashboard"));
    var showDrydockButton = isLoggedIn && status === Status.DRYDOCK_LIMIT;
    var addDrydockButton = (React.createElement(Button, { href: "".concat(props.apiUrl, "/org/").concat(props.orgName, "#request-drydocks"), target: "_blank" },
        React.createElement("i", { className: "fa fa-plus" }),
        "\u00A0\u00A0Add more dry docks"));
    var showStartButton = status === Status.RETIRED && (isLoggedIn || showRestart);
    var startButton = (React.createElement(Button, { onClick: props.startEnvironment, disabled: props.submitting },
        React.createElement("i", { className: "fa fa-play" }),
        "\u00A0\u00A0Start"));
    var showStopButton = isLoggedIn && [Status.BUILDING, Status.DEPLOYING, Status.STARTING].includes(status);
    var stopButton = (React.createElement(Button, { onClick: props.stopEnvironment, disabled: props.submitting },
        React.createElement("i", { className: "fa fa-stop" }),
        "\u00A0\u00A0Stop"));
    return (React.createElement(React.Fragment, null,
        React.createElement("hr", { className: "my-2", style: { borderColor: "gray" } }),
        React.createElement("div", { className: "d-flex flex-gap-1 justify-content-end" },
            React.createElement(Button, { className: "shipyard__btn", href: new URL("/".concat(props.originType, "/").concat(props.uuid, "/detail"), props.apiUrl).toString(), variant: "primary" },
                React.createElement(List, { className: "shipyard__btn__icon" }),
                "Build History"),
            showReloadButton && reloadButton,
            showDashButton && dashboadButton,
            showDrydockButton && addDrydockButton,
            showStartButton && startButton,
            showStopButton && stopButton)));
};
var WaitingForEnvironment = function (props) {
    var userToken = props.userToken, apiUrl = props.apiUrl, uuid = props.uuid, orgName = props.orgName, shipyardToken = props.shipyardToken, showRestart = props.showRestart, buildStatusCode = props.buildStatusCode;
    // TODO: add error div or better error handling
    var _a = useState(null), environment = _a[0], setEnvironment = _a[1];
    var _b = useState([]), siblings = _b[0], setSiblings = _b[1];
    var _c = useState(false), submitting = _c[0], setSubmitting = _c[1];
    var build = environment && (environment.dashboard_build || environment.latest_build);
    var originType = "application"; // Used to allow projects
    var isLoggedIn = !!userToken;
    // Use an enum to establish a few different situations that can be found on the page
    var status = (function () {
        if (!environment || !build)
            return Status.PAGE_LOADING;
        if (build.failed) {
            if (build.failure_reason === "TOO_MANY_BUILDS")
                return Status.DRYDOCK_LIMIT;
            else
                return Status.FAILED;
        }
        if (build.retired)
            return Status.RETIRED;
        if (build.canceled)
            return Status.CANCELED;
        if (build.running)
            return Status.RUNNING;
        if (build.deploying_at)
            return Status.STARTING;
        if (build.built_at)
            return Status.DEPLOYING;
        if (build.queued_at)
            return Status.BUILDING;
        return Status.BUILDING;
    })();
    useEffect(function () {
        var updateEnvironment = function () {
            fetchApplicationAsOauth(uuid, { domain: apiUrl, userToken: userToken, shipyardToken: shipyardToken })
                .then(setEnvironment)
                .catch(console.error);
        };
        updateEnvironment();
        // Simple long polling - fetch environment every five seconds
        var interval = setInterval(updateEnvironment, 5000);
        return function () { return clearInterval(interval); };
    }, []);
    useEffect(function () {
        var fetchSiblings = function (uuid) { return __awaiter(void 0, void 0, void 0, function () {
            var url, headers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = new URL("/api/oauth/".concat(originType, "/").concat(uuid, "/sibling-environments"), apiUrl);
                        if (shipyardToken)
                            url.searchParams.set("shipyard_token", shipyardToken);
                        headers = {};
                        if (userToken)
                            headers = { "x-visitor-token": userToken };
                        return [4 /*yield*/, parseOrThrowRequest(url.toString(), { headers: headers })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        if (environment) {
            fetchSiblings(environment.uuid).then(setSiblings).catch(console.error);
        }
    }, [environment]);
    if (!environment)
        return (React.createElement("div", { className: "w-100 h-100 bg-shipyard-blue-2" },
            React.createElement("div", { className: "page page-center" },
                React.createElement("div", { className: "d-flex justify-center" },
                    React.createElement("div", { className: "card card-md text-white external-card rounded-0" },
                        React.createElement("div", { className: "d-flex flex-column" },
                            React.createElement("div", { className: "d-flex justify-content-between" },
                                React.createElement(Logo, { apiUrl: apiUrl })),
                            React.createElement("h3", { className: "mt-1" }, "Loading...")))))));
    var title = environment.application_set.name + " - " + environment.display_name;
    var startEnvironment = function () {
        var url = new URL("/api/oauth/".concat(originType, "/").concat(uuid, "/restart"), apiUrl);
        var headers = { "x-visitor-token": userToken };
        setSubmitting(true);
        post(url.toString(), "", { headers: headers })
            .then(function (env) {
            setEnvironment(env);
            setSubmitting(false);
        })
            .catch(console.error);
    };
    var stopEnvironment = function () {
        var url = new URL("/api/oauth/".concat(originType, "/").concat(uuid, "/stop"), apiUrl);
        var headers = { "x-visitor-token": userToken };
        setSubmitting(true);
        post(url.toString(), "", { headers: headers })
            .then(function (env) {
            setEnvironment(env);
            setSubmitting(false);
        })
            .catch(console.error);
    };
    return (React.createElement("div", { className: "w-100 h-100 bg-shipyard-blue-2" },
        React.createElement("div", { className: "page page-center" },
            React.createElement("div", { className: "d-flex justify-center" },
                React.createElement("div", { className: "card card-md text-white link-text-white external-card rounded-0" },
                    buildStatusCode && (React.createElement("div", { className: "bg-yellow text-black p-4 mb-4 rounded" },
                        React.createElement("div", { className: "d-flex align-items-center flex-gap-6px type-heading-24px" },
                            React.createElement(AlertTriangle, { size: "1em" }),
                            " ",
                            buildStatusCode))),
                    React.createElement("div", { className: "d-flex justify-content-between" },
                        React.createElement("div", { className: "d-flex flex-column" },
                            React.createElement("div", { className: "d-flex justify-content-between" },
                                React.createElement("img", { src: "".concat(apiUrl, "/static/imgs/logo-icon-white.png"), style: { height: "45px", objectFit: "cover" } })),
                            React.createElement("h3", { className: "mt-1" }, title)),
                        React.createElement("div", { style: { width: "37%", minWidth: "200px" } },
                            React.createElement(SiblingDropdown, { siblings: siblings, originType: originType }))),
                    React.createElement("div", { className: "d-flex justify-content-between" },
                        React.createElement(ApplicationBranchHighlight, { projects: environment.sorted_projects, prProject: environment.pr_project })),
                    [Status.BUILDING, Status.DEPLOYING, Status.STARTING, Status.RUNNING].includes(status) ? (React.createElement(WaitingInfoProgress, { status: status })) : (React.createElement(React.Fragment, null,
                        React.createElement("hr", { className: "my-2", style: { borderColor: "gray" } }),
                        React.createElement(WaitingInfoSnippet, { status: status, failureReason: build.failure_reason, isLoggedIn: isLoggedIn }))),
                    React.createElement(WaitingButtons, { status: status, submitting: submitting, apiUrl: apiUrl, startEnvironment: startEnvironment, stopEnvironment: stopEnvironment, orgName: orgName, showRestart: showRestart, isLoggedIn: isLoggedIn, uuid: uuid, originType: originType }))))));
};
export default WaitingForEnvironment;
