var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { GitBranch, FileText, GitPullRequest } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
var gitProviderToIconName = function (gitProvider) {
    var _a;
    return ((_a = { GITLAB: "fa-gitlab", GITHUB: "fa-github" }) === null || _a === void 0 ? void 0 : _a[gitProvider]) || "fa-github";
};
/** Repo link with a blue background that shrinks with its parent */
export var RepoHighlight = function (_a) {
    var _b = _a.url, url = _b === void 0 ? "" : _b, name = _a.name, isLocked = _a.isLocked, _c = _a.style, style = _c === void 0 ? {} : _c, gitProvider = _a.gitProvider;
    return (React.createElement("a", { href: url, target: "_blank", rel: "noreferrer", className: "d-inline-flex align-items-center branch-highlight", style: style },
        React.createElement(TruncatableText, { text: name, leftContent: React.createElement("i", { className: "fa ".concat(gitProviderToIconName(gitProvider), " bg") }), rightContent: React.createElement("i", { className: "fa fa-".concat(isLocked ? "lock" : "unlock") }) })));
};
export var PrHighLight = function (_a) {
    var url = _a.url, name = _a.name, _b = _a.style, style = _b === void 0 ? {} : _b;
    return (React.createElement("a", { href: url, target: "_blank", rel: "noreferrer", className: "d-inline-flex align-items-center branch-highlight", style: style },
        React.createElement(TruncatableText, { text: name, leftContent: React.createElement(GitPullRequest, { size: ".875rem" }) })));
};
/** Branch link with a blue background that shrinks with its parent */
export var BranchHighlight = function (_a) {
    var url = _a.url, name = _a.name, _b = _a.style, style = _b === void 0 ? {} : _b;
    return (React.createElement("a", { href: url, target: "_blank", rel: "noreferrer", className: "d-inline-flex align-items-center branch-highlight", style: style },
        React.createElement(TruncatableText, { text: name, leftContent: React.createElement(GitBranch, { size: ".875rem" }) })));
};
/** Combo Repo and Branch links with blue backgrounds */
export var RepoAndBranchHighlight = function (_a) {
    var repo = _a.repo, branch = _a.branch, _b = _a.style, style = _b === void 0 ? {} : _b, gitProvider = _a.gitProvider;
    return (React.createElement("div", { className: "d-flex flex-gap-2px", style: { minWidth: 0 } },
        React.createElement("a", { href: repo.url, target: "_blank", rel: "noreferrer", className: "d-inline-flex align-items-center branch-highlight", style: __assign({ borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: 0 }, style) },
            React.createElement(TruncatableText, { text: repo.name, leftContent: React.createElement("i", { className: "fa ".concat(gitProviderToIconName(gitProvider), " bg") }), rightContent: React.createElement("i", { className: "fa fa-".concat(repo.isLocked ? "lock" : "unlock") }) })),
        React.createElement("a", { href: branch.url, target: "_blank", rel: "noreferrer", className: "d-inline-flex align-items-center branch-highlight", style: __assign({ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginRight: 0 }, style) },
            React.createElement(TruncatableText, { text: branch.name, leftContent: React.createElement(GitBranch, { size: ".875rem" }) }))));
};
/** File link with a blue background that shrinks with its parent */
export var FileHighlight = function (_a) {
    var url = _a.url, name = _a.name, _b = _a.style, style = _b === void 0 ? {} : _b, _c = _a.target, target = _c === void 0 ? "_blank" : _c;
    return (React.createElement("a", { href: url, className: "d-inline-flex align-items-center branch-highlight", style: style, target: target, rel: target === "_blank" ? "noreferrer noopener" : "" },
        React.createElement(TruncatableText, { text: name, leftContent: React.createElement(FileText, { size: ".875rem" }) })));
};
/**
 * Text-truncate span with an included tooltip.
 *
 * Requires parent to have flex/inline-flex and min-width of 0.
 */
export var TruncatableText = function (_a) {
    var text = _a.text, _b = _a.leftContent, leftContent = _b === void 0 ? null : _b, _c = _a.rightContent, rightContent = _c === void 0 ? null : _c, _d = _a.className, className = _d === void 0 ? "" : _d, _e = _a.url, url = _e === void 0 ? "" : _e, _f = _a.bold, bold = _f === void 0 ? true : _f, _g = _a.target, target = _g === void 0 ? "_blank" : _g, _h = _a.style, style = _h === void 0 ? {} : _h, _j = _a.showTooltip, showTooltip = _j === void 0 ? true : _j, _k = _a.truncateFromLeft, truncateFromLeft = _k === void 0 ? false : _k;
    var tooltipRef = useRef(null);
    var aRef = useRef(null);
    var textRef = useRef(null);
    var _l = useState(false), show = _l[0], setShow = _l[1];
    var _m = useState(false), isOverflown = _m[0], setIsOverflown = _m[1];
    useEffect(function () {
        var element = textRef.current || aRef.current;
        if (!element)
            return;
        setIsOverflown(element.scrollWidth > element.clientWidth);
    }, [show]);
    var classNames = "text-truncate ".concat(truncateFromLeft ? "direction-rtl" : "direction-ltr", " ").concat(className, " ").concat(bold ? "font-weight-600" : "");
    var truncate = url ? (React.createElement("a", { className: classNames, style: style, ref: aRef, href: url, target: target, rel: "noreferrer" }, text)) : (React.createElement("span", { className: classNames, style: style, ref: textRef }, text));
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "text-truncate-container d-flex flex-gap-6px", ref: tooltipRef, onMouseEnter: function () { return setShow(true); }, onMouseLeave: function () { return setShow(false); } },
            leftContent && (React.createElement("span", { className: "flex-shrink-0 d-inline-flex align-items-center" }, leftContent)),
            truncate,
            rightContent && (React.createElement("span", { className: "flex-shrink-0 d-inline-flex align-items-center" }, rightContent))),
        React.createElement(Overlay, { target: tooltipRef.current, show: showTooltip && show && isOverflown, placement: "top" },
            React.createElement(Tooltip, { id: "truncatable-text-tooltip-".concat(text) }, text))));
};
export var ProjectBranchHighlight = function (_a) {
    var project = _a.project;
    return (React.createElement("span", { className: "d-flex flex-gap-1 align-items-center", style: { minWidth: 0 } },
        project.is_pr && (React.createElement(React.Fragment, null,
            React.createElement(PrHighLight, { name: "".concat(project.parent_branch, ": #").concat(project.pr_number), url: project.pr_url || "" }),
            React.createElement("span", { className: "mr-2" }, "\u2190"))),
        React.createElement(BranchHighlight, { name: project.branch, url: project.remote_branch_url || "" })));
};
var ExtraProjectTooltip = function (_a) {
    var projects = _a.projects, all = _a.all;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var ref = useRef(null);
    var l = projects.length;
    if (l <= 0)
        return null;
    var text = "".concat(all ? "" : "+ ").concat(l, " repo").concat(l === 1 ? "" : "s");
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { style: { color: "var(--blue)", cursor: "pointer" }, ref: ref, onMouseEnter: function () { return setShow(true); }, onMouseLeave: function () { return setShow(false); } }, text),
        React.createElement(Overlay, { target: ref.current, show: show, placement: "top" }, function (_a) {
            var placement = _a.placement, arrowProps = _a.arrowProps, _ = _a.show, popper = _a.popper, overlayProps = __rest(_a, ["placement", "arrowProps", "show", "popper"]);
            return (React.createElement("div", __assign({}, overlayProps, { className: "padded-tooltip text-white ".concat(overlayProps.className) }), projects.map(function (project) { return (React.createElement(RepoHighlight, { gitProvider: project.git_provider_dashboard, key: project.uuid, url: project.repo.url, name: project.github_repo, isLocked: project.repo_private })); })));
        })));
};
/** Show all repos if there is space, otherwise truncate with tooltip */
var ApplicationMultiNonPrHighlight = function (_a) {
    var projects = _a.projects, _b = _a.wrap, wrap = _b === void 0 ? false : _b;
    //
    // This component is a HACK. We need to identify if the children RepoHighlights will be too
    // large, and then render what is needed. For JS to measure a node, it needs to be rendered
    // on the DOM. Thus, we briefly render the RepoHighlights to measure them. I have yet to actually
    // see them on the page, the re-render is so fast.
    var _c = useState(true), calculating = _c[0], setCalculating = _c[1];
    var _d = useState(projects.length), splitIndex = _d[0], setSplitIndex = _d[1];
    var containerRef = useRef(null);
    // Hard-coded approximate width of extra repo tooltip
    var TOOLTIP_WIDTH = 47;
    var calculateIndex = function () {
        if (!containerRef.current)
            return;
        var width = containerRef.current.clientWidth;
        // The repo highlights will be the second child
        var children = Array.from(containerRef.current.children[0].children);
        // First check if we even need to shrink these items
        var totalChildrenWidth = children.reduce(function (acc, b) { return acc + b.clientWidth; }, 0);
        if (totalChildrenWidth <= width) {
            setCalculating(false);
            return;
        }
        var childrenWidth = TOOLTIP_WIDTH;
        // Find index to split at
        for (var index = 0; index < children.length; index++) {
            var child = children[index];
            childrenWidth += child.clientWidth;
            if (childrenWidth > width) {
                setSplitIndex(index);
                setCalculating(false);
                return;
            }
        }
    };
    // TODO: we do not handle screen width changes while on the page, user would have to reload
    useEffect(function () {
        if (calculating && containerRef.current)
            calculateIndex();
    }, [calculating, containerRef.current]);
    return (React.createElement("div", { style: { minWidth: 0, flexGrow: 1 }, ref: containerRef },
        React.createElement("div", { className: "d-flex align-items-center flex-gap-4px", style: {
                flexWrap: wrap ? "wrap" : "nowrap",
            } },
            projects.slice(0, splitIndex).map(function (project) { return (React.createElement(RepoHighlight, { key: project.uuid, url: project.repo.url, name: project.github_repo, isLocked: project.repo_private, gitProvider: project.git_provider_dashboard })); }),
            React.createElement(ExtraProjectTooltip, { projects: projects.slice(splitIndex), all: splitIndex === 0 }))));
};
export var ApplicationBranchHighlight = function (_a) {
    var projects = _a.projects, prProject = _a.prProject;
    if (!projects)
        return null;
    // If there is only one project, we will also include branch information
    var hasSingleProject = (projects === null || projects === void 0 ? void 0 : projects.length) && projects.length === 1;
    var singleProject = projects[0];
    var singleNonPrTitle = function () { return (React.createElement(RepoAndBranchHighlight, { repo: {
            url: singleProject.repo.url,
            name: singleProject.github_repo,
            isLocked: singleProject.repo_private,
        }, branch: {
            url: singleProject.remote_branch_url || "",
            name: singleProject.branch,
        }, gitProvider: singleProject.git_provider_dashboard })); };
    // Includes the branch along with the repo name
    var singlePrTitle = function () { return (React.createElement("div", { className: "d-flex flex-wrap align-items-center flex-gap-8px", style: { minWidth: 0 } },
        React.createElement(RepoAndBranchHighlight, { repo: {
                url: singleProject.repo.url,
                name: singleProject.github_repo,
                isLocked: singleProject.repo_private,
            }, branch: {
                url: singleProject.parent_remote_branch_url || "",
                name: singleProject.parent_branch || "",
            }, gitProvider: singleProject.git_provider_dashboard }),
        React.createElement("span", null, "\u2190"),
        React.createElement(BranchHighlight, { url: singleProject.remote_branch_url || "", name: singleProject.branch }))); };
    var multiPrTitle = function () {
        return prProject ? (React.createElement("span", { className: "app-pr-branches d-inline-flex flex-wrap align-items-center flex-gap-8px", style: { minWidth: 0 } },
            React.createElement(RepoAndBranchHighlight, { repo: {
                    url: prProject.repo.url,
                    name: prProject.github_repo,
                    isLocked: prProject.repo_private,
                }, branch: {
                    url: prProject.parent_remote_branch_url || "",
                    name: prProject.parent_branch || "",
                }, gitProvider: prProject.git_provider_dashboard }),
            React.createElement("span", null, "\u2190"),
            React.createElement(BranchHighlight, { url: prProject.remote_branch_url || "", name: prProject.branch }))) : null;
    };
    return (React.createElement(React.Fragment, null, hasSingleProject ? (prProject ? (singlePrTitle()) : (singleNonPrTitle())) : prProject ? (multiPrTitle()) : (React.createElement(ApplicationMultiNonPrHighlight, { projects: projects, wrap: true }))));
};
