export var Status;
(function (Status) {
    // NO_BUILD = "no-build",
    Status["PAGE_LOADING"] = "loading";
    Status["RETIRED"] = "retired";
    Status["DRYDOCK_LIMIT"] = "drydock-limit";
    Status["CANCELED"] = "canceled";
    Status["FAILED"] = "failed";
    Status["BUILDING"] = "building";
    Status["DEPLOYING"] = "deploying";
    Status["STARTING"] = "starting";
    Status["RUNNING"] = "running";
})(Status || (Status = {}));
