var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from "classnames";
import React, { forwardRef } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
/** A thin wrapper for Bootstrap's Button with Shipyard-specific customizations */
var Button = forwardRef(function (props, ref) {
    var children = props.children, className = props.className, disabled = props.disabled, iconStart = props.iconStart, iconEnd = props.iconEnd, variant = props.variant, rest = __rest(props, ["children", "className", "disabled", "iconStart", "iconEnd", "variant"]);
    var bootstrapVariant = variant === "gitlab" || variant === "github" ? undefined : variant;
    return (React.createElement(BootstrapButton, __assign({ className: classNames(className, "btn-8f3f3f52 d-inline-flex flex-gap-6px align-items-center flex-shrink-0 position-relative", {
            "bg-black bg-gray-darkest:hover text-white": variant === "github",
        }, {
            "bg-gitlab-purple bg-gitlab-purple-dark:hover text-white": variant === "gitlab",
        }), disabled: disabled, ref: ref, variant: bootstrapVariant }, rest),
        iconStart,
        children,
        iconEnd));
});
Button.displayName = "Button";
export default Button;
