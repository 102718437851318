import { serviceLogStatus } from "../constants";
export var getHealthCheckFailures = function (applicationBuild) {
    var failingServices = [];
    applicationBuild.grouped_builds.forEach(function (build) {
        var _a, _b;
        if (!build.failure_metadata || build.failure_metadata.length === 0)
            return;
        var serviceUpdate = (_b = (_a = build.failure_metadata[0]) === null || _a === void 0 ? void 0 : _a.deployUpdate) === null || _b === void 0 ? void 0 : _b.serviceUpdate;
        if (!serviceUpdate)
            return;
        return serviceUpdate.forEach(function (_a) {
            var serviceName = _a.serviceName, status = _a.status, failureMessage = _a.failureMessage;
            if (status === "HEALTH_CHECK_FAILED") {
                failingServices.push({ serviceName: serviceName, failureMessage: failureMessage });
            }
        });
    });
    return failingServices;
};
export var buildIsActive = function (status) {
    return (status === "QUEUED" ||
        status === "PREPROCESSING" ||
        status === "REPOSITORY_DOWNLOADED" ||
        status === "BUILDING" ||
        status === "DEPLOYING" ||
        status === "STARTING" ||
        status === "RUNNING" ||
        status === "BUILT" ||
        status === "DISTRIBUTING" ||
        status === "PREPARING" ||
        status === "CONNECTING");
};
export var buildCumulativeStatus = function (_a) {
    var applicationBuild = _a.applicationBuild, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, _c = _a.isK8S, isK8S = _c === void 0 ? false : _c;
    if (isK8S) {
        return serviceLogStatus.DNE;
    }
    else if (isLoading) {
        return serviceLogStatus.LOADING;
    }
    else if (applicationBuild.failure_reason === "PULLING_IMAGE") {
        // HACK: PULLING_IMAGE should always fail build step for now
        // https://app.shortcut.com/shipyard/story/18541
        return serviceLogStatus.FAILED;
    }
    else if (applicationBuild.building) {
        return serviceLogStatus.ACTIVE;
    }
    else if (applicationBuild.built_at) {
        return serviceLogStatus.SUCCESSFUL;
    }
    else if (applicationBuild.failed && !applicationBuild.built_at) {
        return serviceLogStatus.FAILED;
    }
    else if (applicationBuild.canceled_at && !applicationBuild.built_at) {
        return serviceLogStatus.CANCELED;
    }
    else {
        return serviceLogStatus.DNE;
    }
};
export var deployCumulativeStatus = function (_a) {
    var applicationBuild = _a.applicationBuild, isLoadingPodTimelines = _a.isLoadingPodTimelines;
    // Derive the deploy status from build timestamps.
    // Can't use ApplicationBuild.cumulative_status as
    // FAILED/CANCELED statuses doesn't include
    // information on whether the build was started or not.
    if (isLoadingPodTimelines) {
        return serviceLogStatus.LOADING;
    }
    else if (!applicationBuild.deploying_at) {
        return serviceLogStatus.DNE;
    }
    else if (applicationBuild.failure_reason === "PULLING_IMAGE") {
        // Hack - never show run logs for PULLING_IMAGE
        // https://app.shortcut.com/shipyard/story/18541
        return serviceLogStatus.DNE;
    }
    else if (applicationBuild.deploying_at &&
        !applicationBuild.failed_at &&
        !applicationBuild.started_at &&
        !applicationBuild.canceled_at) {
        return serviceLogStatus.ACTIVE;
    }
    else if (applicationBuild.started_at) {
        return serviceLogStatus.SUCCESSFUL;
    }
    else if (applicationBuild.deploying_at &&
        !applicationBuild.started_at &&
        applicationBuild.canceled_at) {
        return serviceLogStatus.CANCELED;
    }
    else if ((applicationBuild.failed_at && !applicationBuild.connecting_at) ||
        // Unencessary considering above check?
        applicationBuild.failure_reason === "HEALTH_CHECK_FAILED") {
        return serviceLogStatus.FAILED;
    }
    else {
        return serviceLogStatus.DNE;
    }
};
export var healthCheckErrorsForAllServices = function (builds) {
    var result = {};
    // Multi repo app has multiple builds per application build
    builds.forEach(function (build) {
        var _a, _b, _c;
        // Akshay 22 feb 2024:
        // failure_metadata is an array, to keep support for multiple errors down the line,
        // though currently it will just be single object inside that array.
        // Deploy updates only
        if (build.failure_metadata && ((_a = build.failure_metadata) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var serviceUpdate = (_c = (_b = build.failure_metadata[0]) === null || _b === void 0 ? void 0 : _b.deployUpdate) === null || _c === void 0 ? void 0 : _c.serviceUpdate;
            if (serviceUpdate) {
                return serviceUpdate.forEach(function (_a) {
                    var serviceName = _a.serviceName, status = _a.status, failureMessage = _a.failureMessage;
                    if (status === "HEALTH_CHECK_FAILED") {
                        result[serviceName] = failureMessage;
                    }
                });
            }
        }
    });
    return result;
}; // TODO change to Record<BuildStatus, string>
export var applicationBuildDeployServiceUpdates = function (applicationBuild) {
    var deployServiceUpdates = [];
    applicationBuild.grouped_builds.forEach(function (build) {
        var _a, _b;
        if (!build.failure_metadata || build.failure_metadata.length === 0)
            return;
        var serviceUpdate = (_b = (_a = build.failure_metadata[0]) === null || _a === void 0 ? void 0 : _a.deployUpdate) === null || _b === void 0 ? void 0 : _b.serviceUpdate;
        if (!serviceUpdate)
            return;
        // deep clone service update and add to deployUpdates and add to deployServiceUpdates
        return serviceUpdate.forEach(function (_a) {
            var serviceName = _a.serviceName, status = _a.status, failureMessage = _a.failureMessage;
            deployServiceUpdates.push({ serviceName: serviceName, status: status, failureMessage: failureMessage });
        });
    });
    return deployServiceUpdates;
};
export var buildStatusToLevel = {
    QUEUED: "info",
    PREPROCESSING: "info",
    REPOSITORY_DOWNLOADED: "info",
    BUILDING: "info",
    DEPLOYING: "info",
    STARTING: "info",
    RUNNING: "success",
    RETIRED: "gray",
    FAILED: "danger",
    BUILT: "success",
    SHORT_CIRCUIT: "danger",
    DISTRIBUTING: "info",
    PREPARING: "info",
    CANCELED: "gray",
    CONNECTING: "info",
};
export var readableBuildFailure = function (failureReason) {
    if (!failureReason)
        return "";
    var match = {
        BRANCH_DOES_NOT_EXIST: "Branch does not exist",
        BUILD_ERROR_UNSPECIFIED: "Unspecified build error",
        BUILDING_IMAGES: "Failure building images",
        BUILDKIT_DOWN: "Failure building images",
        CHECKING_ROWBOAT: "Failure routing to environment",
        CLUSTER_PROVISION_ERROR: "Error provisioning cluster",
        COMPOSE_NOT_VALID_YAML: "Compose file isn't valid YAML",
        CONNECTING_CLUSTER: "Failure connecting to your cluster",
        CONTAINER_CRASHED: "Container(s) crashed",
        DEPLOYING_APP: "Remote deploy failed",
        DEPLOYING_ROWBOAT: "Failure routing to environment",
        DOCKER_BUILD_OOM: "Build process ran out of memory",
        DOCKER_HUB_DOWN: "There was an issue with Docker Hub",
        DOWNLOADING_REPO: "Failure downloading repo",
        DUPLICATE_PORTS: "Services have conflicting host ports",
        DUPLICATE_ROUTES: "Two services share the same route",
        ERROR_PREPROCESSING: "Failure preprocessing build",
        ERROR_RETRIEVING_COMMIT: "Failure retrieving commit metadata",
        ERROR_RETRIEVING_COMPOSE: "Failure retrieving Compose file",
        ERROR_RETRIEVING_DOCKERFILE: "Unable to retrieve Dockerfiles",
        HEALTH_CHECK_FAILED: "A service failed its health check",
        INITIALIZING_RESTIC: "Error initializing volumes",
        INVALID_COMPOSE_FILE: "Invalid Compose file",
        INVALID_COMPOSE_FILE_TYPE: "Compose isn't a valid file type",
        INVALID_COMPOSE_SHIPYARD_LABEL: "One of your shipyard-specific labels is not valid",
        INVALID_LIVENESS_PROBE: "Invalid liveness probe definition",
        INVALID_PRIVATE_REGISTRY_CREDENTIALS: "Invalid private registry credentials",
        INVALID_READINESS_PROBE: "Invalid readiness probe definition",
        INVALID_ROWBOAT_CONFIG: "Error routing to environment",
        INVALID_SERVICE_USER: "Invalid 'user' in compose service",
        K8S_MANIFEST_ERROR_RETRIEVING: "Failure retrieving Kubernetes manifest",
        K8S_MANIFEST_INVALID_FILE: "Invalid Kubernetes manifest",
        K8S_MANIFEST_INVALID_FILE_TYPE: "Kubernetes manifest isn't a valid file type",
        K8S_MANIFEST_NOT_VALID_YAML: "Kubernetes manifest isn't valid YAML",
        MANUALLY_FAILED: "Build was manually failed",
        MISSING_CLUSTER: "Your organization's cluster isn't ready",
        NETWORK_ERROR: "Failure connecting to environment",
        NEVER_STARTED: "Container(s) failed to run",
        NO_COMPOSE_FILE: "No Compose file found!",
        NO_COMPOSE_SERVICES: "No services found in Compose file",
        PULLING_IMAGE: "Unable to pull images",
        REGISTRY_SERVICE_UNAVAILABLE: "The private registry host is unreachable",
        ROUTE_WITHOUT_PORTS: "A service has a defined shipyard.route but no ports",
        STUCK: "Build was manually marked as stale",
        TAGGING_IMAGE: "Failure tagging images",
        TOO_MANY_BUILDS: "Out of Drydocks",
        UNDERSCORE_IN_COMPOSE_SERVICE_NAME: "Underscore not allowed in Compose service name",
        UPLOADING_IMAGE: "Failure uploading images",
        VERIFYING_BUILD: "Failure verifying environment health",
        WRONG_IMAGE_PLATFORM: "Image is not on linux/amd64",
        NO_ENABLED_ROUTES: "No enabled routes",
        IMAGE_ACCESS_DENIED: "Images are not accessible",
    };
    var reason = match[failureReason];
    if (!reason)
        return (failureReason.charAt(0).toUpperCase() +
            failureReason.toLowerCase().replaceAll("_", " ").slice(1));
    return reason;
};
export var buildFailureLabel = {
    BRANCH_DOES_NOT_EXIST: "Branch does not exist",
    BUILD_ERROR_UNSPECIFIED: "Unspecified build error",
    BUILDING_IMAGES: "Failed building images",
    BUILDKIT_DOWN: "Buildkit down",
    CHECKING_ROWBOAT: "Routing failure",
    CLUSTER_PROVISION_ERROR: "Cluster provision error",
    COMPOSE_NOT_VALID_YAML: "Compose not valid YAML",
    CONNECTING_CLUSTER: "Connecting cluster",
    CONTAINER_CRASHED: "Container crashed",
    DEPLOYING_APP: "Remote deploy failed",
    DEPLOYING_ROWBOAT: "Routing failure",
    DOCKER_BUILD_OOM: "Out of memory",
    DOCKER_HUB_DOWN: "Docker hub down",
    DOWNLOADING_REPO: "Failed downloading repo",
    DUPLICATE_PORTS: "Duplicate ports",
    DUPLICATE_ROUTES: "Duplicate routes",
    ERROR_PREPROCESSING: "Failed preprocessing",
    ERROR_RETRIEVING_COMMIT: "Failed retrieving commit",
    ERROR_RETRIEVING_COMPOSE: "Failed retrieving compose",
    ERROR_RETRIEVING_DOCKERFILE: "Failed retrieving dockerfile",
    HEALTH_CHECK_FAILED: "Health check failed",
    INITIALIZING_RESTIC: "Failed initializing Volumes",
    INTERNAL: "Internal error",
    INVALID_COMPOSE_FILE: "Invalid compose file",
    INVALID_COMPOSE_FILE_TYPE: "Invalid compose file type",
    INVALID_COMPOSE_SHIPYARD_LABEL: "Invalid compose shipyard label",
    INVALID_LIVENESS_PROBE: "Invalid liveness probe",
    INVALID_PRIVATE_REGISTRY_CREDENTIALS: "Invalid private registry credentials",
    INVALID_READINESS_PROBE: "Invalid readiness probe definition",
    INVALID_ROWBOAT_CONFIG: "Invalid rowboat config",
    INVALID_SERVICE_USER: "Invalid 'user' in compose",
    K8S_MANIFEST_ERROR_RETRIEVING: "Failed retrieving K8s manifest",
    K8S_MANIFEST_INVALID_FILE: "Invalid K8s manifest file",
    K8S_MANIFEST_INVALID_FILE_TYPE: "Invalid K8s manifest file type",
    K8S_MANIFEST_NOT_VALID_YAML: "K8s manifest not valid YAML",
    MANUALLY_FAILED: "Build manually failed",
    MISSING_CLUSTER: "Missing cluster",
    MISSING_IMAGE_TEMPLATE: "Missing image template",
    NETWORK_ERROR: "Network error",
    NEVER_STARTED: "Container(s) failed to run",
    NO_COMPOSE_FILE: "No compose file",
    NO_COMPOSE_SERVICES: "No compose services",
    PULLING_IMAGE: "Failed pulling image",
    REGISTRY_SERVICE_UNAVAILABLE: "Private registry unavailable",
    ROUTE_WITHOUT_PORTS: "Route without ports",
    STUCK: "Manually marked as stale",
    TAGGING_IMAGE: "Failed tagging image",
    TOO_MANY_BUILDS: "Out of Drydocks",
    UNDERSCORE_IN_COMPOSE_SERVICE_NAME: "Underscore in compose service name",
    UPLOADING_IMAGE: "Failed uploading image",
    VERIFYING_BUILD: "Verifying build",
    WRONG_IMAGE_PLATFORM: "Image not linux/amd64",
    NO_ENABLED_ROUTES: "No enabled routes",
    MISSING_IMAGE: "Image not found",
    IMAGE_ACCESS_DENIED: "Images are not accessible",
};
export var imageBuildFailureLabels = {
    BUILDKIT_DOWN: "Buildkit down",
    BUILD_FAILED: "Build failed",
    DOCKERFILE_INVALID: "Dockerfile invalid",
    DOCKERFILE_MISSING: "Dockerfile missing",
    DOCKER_HUB_DOWN: "Docker hub down",
    IMAGE_BUILD_FAILURE_UNSPECIFIED: "Image build failure unspecified",
    INVALID_PRIVATE_REGISTRY_CREDENTIALS: "Invalid private registry credentials",
    OUT_OF_MEMORY: "Out of memory",
    PULLING_IMAGE: "Pulling image",
    REGISTRY_SERVICE_UNAVAILABLE: "Registry service unavailable",
    TIMEOUT: "Timeout",
    WORKER_CRASHED: "Worker crashed",
    WRONG_IMAGE_PLATFORM: "Wrong image platform",
};
export var gitFailureLabels = {
    FAILURE_UNDEFINED: "Undefined git failure",
    BRANCH_NOT_FOUND: "Git branch not found",
    INVALID_REFERENCE: "Invalid git reference",
    REPOSITORY_NOT_FOUND: "Git repository not found",
    REMOTE_NOT_FOUND: "Git remote not found",
    UNABLE_TO_RESOLVE: "Unable to resolve",
    REFERENCE_NOT_FOUND: "Reference not found",
    UNAUTHORIZED_SUBMODULE: "Unauthorized git submodule",
};
export var buildStatusLabels = {
    QUEUED: "Queued",
    PREPROCESSING: "Preprocessing",
    REPOSITORY_DOWNLOADED: "Repository Downloaded",
    BUILDING: "Building",
    DEPLOYING: "Deploying",
    STARTING: "Starting",
    RUNNING: "Running",
    RETIRED: "Stopped",
    FAILED: "Build Error",
    BUILT: "Built",
    SHORT_CIRCUIT: "Short Circuit",
    DISTRIBUTING: "Distributing",
    PREPARING: "Preparing",
    CANCELED: "Canceled",
    CONNECTING: "Connecting",
};
export var buildStatusToHighLevelStatus = function (build) {
    if (build.running) {
        return "success";
    }
    else if (build.failure_reason === "TOO_MANY_BUILDS") {
        return "out-of-dry-docks";
    }
    else if (build.failed) {
        return "error";
    }
    else if (build.canceled || build.retired) {
        return "stopped";
    }
    else {
        return "in-progress";
    }
};
export var highLevelStatusToColor = function (highLevelStatus) {
    var statusColors = {
        success: { backgroundColor: "success", color: "light" },
        "out-of-dry-docks": { backgroundColor: "yellow", color: "light" },
        error: { backgroundColor: "red", color: "light" },
        stopped: { backgroundColor: "gray-lighter", color: "dark" },
        "in-progress": { backgroundColor: "blue", color: "light" },
    };
    return statusColors[highLevelStatus];
};
