var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
export var Skeleton = function (_a) {
    var _b = _a.width, width = _b === void 0 ? "28px" : _b, _c = _a.height, height = _c === void 0 ? "21px" : _c, _d = _a.className, className = _d === void 0 ? "" : _d;
    return (React.createElement("span", { className: "d-block skeleton ".concat(className), style: { width: "".concat(width), height: "".concat(height) } }));
};
// Single line text only
export var TextSkeleton = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, children = _a.children;
    return (React.createElement("span", { style: { display: "block", position: "relative", width: "min-content" } },
        React.createElement("span", { className: "d-block skeleton ".concat(className), style: {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "0.8em",
                marginTop: "0.35em",
            } }),
        React.createElement("span", { "aria-hidden": true, style: { whiteSpace: "nowrap", opacity: 0 } }, children)));
};
export var ThreeDotsAnimation = function () { return (React.createElement("span", { className: "d-flex" },
    React.createElement("span", { className: "dot-flashing", style: { animationDelay: "-.5s" } }),
    React.createElement("span", { className: "dot-flashing", style: { marginRight: "3.5px", marginLeft: "3.5px" } }),
    React.createElement("span", { className: "dot-flashing", style: { animationDelay: ".5s" } }))); };
export var LoaderLargeCrane = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "rgba(44,97,170,1)" : _b, _c = _a.width, width = _c === void 0 ? "92px" : _c;
    return (React.createElement("svg", { style: { height: "auto", width: width }, viewBox: "0 0 213 175", fill: "none" },
        React.createElement("g", null,
            React.createElement("path", { d: "M100.208 120.267H70.8516V126.738H100.208V120.267Z", fill: color, className: "box box-1" }),
            React.createElement("path", { d: "M100.208 108.545H70.8516V114.89H100.208V108.545Z", fill: color, className: "box box-2" }),
            React.createElement("path", { d: "M100.208 97.1079H70.8516V103.325H100.208V97.1079Z", fill: color, className: "box box-3" })),
        React.createElement("g", null,
            React.createElement("path", { d: "M52.2078 120.267H22.8516V126.738H52.2078V120.267Z", fill: color, className: "box box-4" }),
            React.createElement("path", { d: "M52.2078 108.545H22.8516V114.89H52.2078V108.545Z", fill: color, className: "box box-5" }),
            React.createElement("path", { d: "M52.2078 97.1079H22.8516V103.325H52.2078V97.1079Z", fill: color, className: "box box-6" })),
        React.createElement("path", { d: "M209.637 53.4893V83.9893H186.772V68.7393H179.137V84.0187H171.522V153.338H181.248L192.569 171.325H124.667L135.987 153.338H141.022V84.0187H133.387V68.7393H1.77734L19.3637 50.1656H124.012L126.211 53.4893H141.022L154.883 0.974609L171.091 53.4893H209.637ZM148.637 153.338H162.997L163.887 136.504L148.637 150.864V153.338ZM148.647 140.326L163.887 125.976V114.421L148.647 128.772V140.326ZM148.637 119.211L163.887 104.86V93.3055L148.637 107.666V119.211ZM148.647 98.0956L163.887 85.1526H148.647V98.0956ZM141.002 76.3546H163.897V61.1241H141.002V76.3546ZM81.2924 61.9844H93.6683L98.7321 56.9108H86.366L81.2924 61.9844ZM38.6022 61.9844H50.9684L56.0321 56.9108H43.666L38.6022 61.9844ZM17.262 61.9844H29.6281L34.6919 56.9108H22.3257L17.262 61.9844ZM59.9619 61.9844H72.3281L77.3919 56.9108H65.0257L59.9619 61.9844ZM102.897 61.9844H123.728L120.375 56.9108H107.97L102.897 61.9844ZM165.177 53.4893L161.453 41.436L148.656 53.4893H165.177ZM159.165 34.026L155.314 21.5425L149.634 43.0099L159.165 34.026Z", fill: color }),
        React.createElement("path", { d: "M9.93945 131.694V171.325H112.955V131.694H9.93945ZM41.6223 162.83H17.8675V140.649H41.6223V162.83ZM65.4064 162.83H49.5601V140.649H65.4064V162.83ZM97.0991 162.83H73.3149V140.649H97.0991V162.83ZM105.359 83.1973H112.955V126.738H105.359V83.1973ZM57.5273 93.8234H65.3673V126.738H57.5273V93.8234ZM9.93945 110.061H17.8675V126.738H9.93945V110.061Z", fill: color })));
};
// Adapted from feather's react icons
export var LoadingSpinner = forwardRef(function (_a, ref) {
    var _b = _a.size, size = _b === void 0 ? 12 : _b, rest = __rest(_a, ["size"]);
    return (React.createElement("svg", __assign({ ref: ref, xmlns: "http://www.w3.org/2000/svg", width: size, height: size, viewBox: "0 0 12 12", fill: "#467FCF", className: "shipyard__loader-sm" }, rest),
        React.createElement("path", { d: "M6 0V2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6H0C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0\nZ" })));
});
LoadingSpinner.displayName = "LoadingSpinner";
