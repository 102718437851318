var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// Flask can throw a JSON or HTML error response for the same endpoint, depending on the status code
// We need to be able to handle & parse either kind
export var responseErrorText = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var json, error, keys, key, value, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (response.status === 200) {
                    throw "You should only call this for a failed request";
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, response.json()];
            case 2:
                json = _a.sent();
                // Due to legacy reason, the backend can return different json objects depending on the status code error
                if (json.error) {
                    error = json.error;
                    if (typeof error === "string")
                        return [2 /*return*/, error];
                    // HACK: should probably handle multiple error strings, but for now just return first value
                    if (Array.isArray(error) && error.length)
                        return [2 /*return*/, error[0]];
                    else {
                        keys = Object.keys(error);
                        if (keys.length) {
                            key = keys[0];
                            value = error[key];
                            if (Array.isArray(value) && value.length)
                                return [2 /*return*/, "Error: ".concat(key, ": ").concat(value[0])];
                            else if (typeof value === "string")
                                return [2 /*return*/, "Error: ".concat(key, ": ").concat(value)];
                        }
                    }
                    return [2 /*return*/, "Error: badly formatted error message"];
                }
                if (json.resource_identifier && response.status === 403) {
                    return [2 /*return*/, "Unauthorized to operate on ".concat(json.resource_type, " with identifier ").concat(json.resource_identifier, "!")];
                }
                if (json.resource_identifier && response.status === 404) {
                    return [2 /*return*/, "No ".concat(json.resource_type, " with identifier ").concat(json.resource_identifier, " was found!")];
                }
                return [2 /*return*/, json];
            case 3:
                error_1 = _a.sent();
                // HTML errors have less info, and are not JSON parseable
                return [2 /*return*/, response.status.toString() + " " + response.statusText];
            case 4: return [2 /*return*/];
        }
    });
}); };
/** Simple helper method to make a GET request and return or throw the response.
 * @param {string | URL} url - URL to make request to
 * @param {object} options - Additional optional options
 * @param {object} options.headers - Additional headers to set on the fetch
 */
export var parseOrThrowRequest = function (url, _a) {
    var _b = _a === void 0 ? {
        headers: {},
    } : _a, _c = _b.headers, headers = _c === void 0 ? {} : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var _headers, res;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _headers = new Headers();
                    Object.entries(headers).forEach(function (_a) {
                        var key = _a[0], value = _a[1];
                        return _headers.append(key, value);
                    });
                    return [4 /*yield*/, fetch(url.toString(), { headers: _headers })];
                case 1:
                    res = _d.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, responseErrorText(res)];
                case 2: throw _d.sent();
                case 3:
                    if (res.status === 204)
                        return [2 /*return*/];
                    return [4 /*yield*/, res.json()];
                case 4: return [2 /*return*/, _d.sent()];
            }
        });
    });
};
export var deleteRequest = function (url, data) {
    if (data === void 0) { data = undefined; }
    return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(url.toString(), {
                        method: "DELETE",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(data),
                    })];
                case 1:
                    res = _a.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, responseErrorText(res)];
                case 2: throw _a.sent();
                case 3:
                    if (res.status === 204)
                        return [2 /*return*/];
                    return [4 /*yield*/, res.json()];
                case 4: return [2 /*return*/, _a.sent()];
            }
        });
    });
};
export var post = function (url, data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.headers, headers = _c === void 0 ? {} : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, fetch(url.toString(), {
                        method: "POST",
                        headers: __assign({ "Content-Type": "application/json" }, headers),
                        body: JSON.stringify(data),
                    })];
                case 1:
                    res = _d.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, responseErrorText(res)];
                case 2: throw _d.sent();
                case 3:
                    if (res.status === 204)
                        return [2 /*return*/];
                    return [4 /*yield*/, res.json()];
                case 4: return [2 /*return*/, _d.sent()];
            }
        });
    });
};
export var put = function (url, data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.headers, headers = _c === void 0 ? {} : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, fetch(url.toString(), {
                        method: "PUT",
                        headers: __assign({ "Content-Type": "application/json" }, headers),
                        body: JSON.stringify(data),
                    })];
                case 1:
                    res = _d.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, responseErrorText(res)];
                case 2: throw _d.sent();
                case 3:
                    if (res.status === 204)
                        return [2 /*return*/];
                    return [4 /*yield*/, res.json()];
                case 4: return [2 /*return*/, _d.sent()];
            }
        });
    });
};
export var postFormData = function (url, formData) { return __awaiter(void 0, void 0, void 0, function () {
    var res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(url, {
                    method: "POST",
                    body: formData,
                })];
            case 1:
                res = _a.sent();
                if (!!res.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, responseErrorText(res)];
            case 2: throw _a.sent();
            case 3:
                if (res.status === 204)
                    return [2 /*return*/];
                return [4 /*yield*/, res.json()];
            case 4: return [2 /*return*/, _a.sent()];
        }
    });
}); };
