// TODO: these should be PascaleCase
export var serviceLogType;
(function (serviceLogType) {
    serviceLogType["BUILD_LOG"] = "build_log";
    serviceLogType["INIT_LOG"] = "init_log";
    serviceLogType["RUN_LOG"] = "run_log";
    serviceLogType["CRASH_LOG"] = "crash_log";
    serviceLogType["DEPLOY_LOG"] = "deploy_log";
})(serviceLogType || (serviceLogType = {}));
export var serviceLogStatus;
(function (serviceLogStatus) {
    serviceLogStatus["DNE"] = "dne";
    serviceLogStatus["ACTIVE"] = "active";
    serviceLogStatus["SUCCESSFUL"] = "successful";
    serviceLogStatus["FAILED"] = "failed";
    serviceLogStatus["CANCELED"] = "canceled";
    serviceLogStatus["LOADING"] = "loading";
})(serviceLogStatus || (serviceLogStatus = {}));
export var buildType;
(function (buildType) {
    buildType["APPLICATION_BUILD"] = "application-build";
    buildType["BUILD"] = "build";
})(buildType || (buildType = {}));
export var envType;
(function (envType) {
    envType["APPLICATION"] = "application";
    envType["APPLICATION_SET"] = "application-set";
    envType["PROJECT"] = "project";
    envType["REPO"] = "repo";
})(envType || (envType = {}));
// TODO: should just be a const
export var docLink;
(function (docLink) {
    docLink["SUPPORTED_SERVICE_LABELS"] = "https://docs.shipyard.build/docs/docker-compose#supported-service-labels";
    docLink["DEFINE_COMPOSE"] = "https://docs.shipyard.build/docs/docker-compose";
    docLink["CORS_REQUESTS"] = "https://docs.shipyard.build/docs/authentication#cors-requests";
    docLink["ENV_VARS"] = "https://docs.shipyard.build/docs/secrets";
})(docLink || (docLink = {}));
export var ShipyardLink = {
    GETTING_STARTED_VIDEO: "https://www.youtube.com/watch?v=D9bDkwI7wJ4",
    EMAIL_CONTACT: "mailto:hello@shipyard.build",
    CONTACT: "https://shipyard.build/contact/",
    DOCUMENTATION: "https://docs.shipyard.build",
    PRICING_PLANS: "https://shipyard.build/pricing/",
    SLACK_COMMUNITY_INVITE: "https://join.slack.com/t/shipyardcommunity/shared_invite/zt-1y44cpq6u-rJT~kg9wArqxP~N1F3K_pA",
    CDN_URL: SHIPYARD_CDN || new URL("/static", window.location.origin).toString(),
};
export var DEFAULT_SLV = 5; // Hours
export var DEFAULT_HEALTH_CHECK_MINUTES = 10; // Minutes
export var HEALTH_CHECK_MAX_MINUTES = 30; // Minutes
export var DEFAULT_TERMINATION_GRACE_PERIOD = 30; // Seconds
export var DEFAULT_TERMINATION_GRACE_PERIOD_MAX = 30 * 60; // 30 Minutes (1800 seconds)
