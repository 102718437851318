/** LEGACY. Do not add new methods to this file. All methods should be taken
 * out and put into a file inside this folder, corresponding to the `api/` file
 * of their endpoint.
 */

import { ALLOWED_ORIGINS } from "./constants";
import { responseErrorText, parseOrThrowRequest, deleteRequest, post } from "./utils";

export const fetchOrgs = async (fields = "") => {
  const url = new URL("/api/orgs", window.location.href);
  if (fields) url.searchParams.set("fields", fields);
  return await parseOrThrowRequest(url);
};

export const configureOrg = async (orgName, setting, value) => {
  return await post(`/api/org/${orgName}/configure/${setting}`, { value });
};

export const fetchOrgPrivateRegistries = async (orgName) => {
  const url = `/api/org/${orgName}/private-registry`;
  return await parseOrThrowRequest(url);
};

export const fetchOrgApplicationSetNames = async (orgName) => {
  const url = `/api/org/${orgName}/application-set-names`;
  return await parseOrThrowRequest(url);
};

/** User audit events for an org, or all orgs if none is specified */
export const fetchPaginatedUserEvents = async ({
  orgName = "",
  startTime = "",
  endTime = "",
  page = 1,
} = {}) => {
  const url = new URL("/api/audit_events", window.location.href);
  if (orgName) url.searchParams.set("org_name", orgName);
  if (startTime) url.searchParams.set("start_time", startTime);
  if (endTime) url.searchParams.set("end_time", endTime);
  url.searchParams.set("page", page);
  return await parseOrThrowRequest(url);
};

export const fetchUserEventSummary = async ({
  orgName = "",
  startTime = "",
  endTime = "",
} = {}) => {
  const url = new URL("/api/audit_events/audit_events_summary", window.location.href);
  if (orgName) url.searchParams.set("org_name", orgName);
  if (startTime) url.searchParams.set("start_time", startTime);
  if (endTime) url.searchParams.set("end_time", endTime);
  return await parseOrThrowRequest(url);
};

export const fetchPaginatedRepos = async (orgName, pageNum = 1) => {
  const url = `/api/repo/${orgName}/${pageNum}`;
  return await parseOrThrowRequest(url);
};

export const fetchPaginatedProjectBuilds = async (projectUuid, pageNum = 1) => {
  const url = `/api/project/${projectUuid}/past-builds/${pageNum}`;
  return await parseOrThrowRequest(url);
};

// NOTE: this currently just returns the full repo
// Leaving the name as-is to futureproof it a little more
export const fetchRepoSettings = async (repoId) => {
  const url = `/api/repo/${repoId}/settings`;
  return await parseOrThrowRequest(url);
};

export const fetchRepoGithubLabels = async (repoId) => {
  const url = `/api/repo/${repoId}/github-labels`;
  return await parseOrThrowRequest(url);
};

export const fetchRepoPrLabels = async (repoId) => {
  const url = `/api/repo/${repoId}/pr-labels`;
  return await parseOrThrowRequest(url);
};
export const updateRepoPrLabels = async (repoId, labels) => {
  const url = `/api/repo/${repoId}/pr-labels`;
  return await post(url, labels);
};

export const fetchRepoImageWait = async (repoId) => {
  const url = `/api/repo/${repoId}/image-wait-threshold`;
  return await parseOrThrowRequest(url);
};
export const updateRepoImageWait = async (repoId, seconds) => {
  const url = `/api/repo/${repoId}/image-wait-threshold`;
  return await post(url, { image_wait_threshold: seconds });
};

export const fetchIssueTrackedProjects = async (orgName) => {
  const url = `/api/org/${orgName}/issue-tracked-projects`;
  return await parseOrThrowRequest(url);
};

export const fetchAllPods = async (applicationBuildId) => {
  const url = `/api/application-build/${applicationBuildId}/pods`;
  const res = await fetch(url);
  if (!res.ok) throw await responseErrorText(res);
  return await res.json();
};

/** Fetch a full project */
export const fetchProject = async (projectId, params = {}) => {
  const url = new URL(`/api/project/${projectId}`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url.toString());
};

export const fetchProjectAsOauth = async (
  projectId,
  { domain = window.location.href, userToken = "", shipyardToken = "" } = {}
) => {
  const url = new URL(`/api/oauth/project/${projectId}`, domain);
  if (shipyardToken) url.searchParams.set("shipyard_token", shipyardToken);

  let headers = {};
  if (userToken) headers = { "x-visitor-token": userToken };
  return await parseOrThrowRequest(url.toString(), { headers: headers });
};

export const fetchProjectSettings = async (projectId) => {
  const url = `/api/project/${projectId}/settings`;
  return await parseOrThrowRequest(url);
};

export const fetchProjectPastBuilds = async (projectId) => {
  const url = `/api/project/${projectId}/past-builds`;
  return await parseOrThrowRequest(url);
};

export const updateProjectIssueTrackerState = async (projectId, state) => {
  const url = `/api/project/${projectId}/issue-state`;
  return await post(url, state);
};

export const fetchProjectIssuesByTag = async (projectId) => {
  const url = `/api/project/${projectId}/issues-by-tag`;
  return await parseOrThrowRequest(url);
};

export const fetchProjectIssueRecording = async (projectId, issueId) => {
  const url = `/api/project/${projectId}/issue/${issueId}/recording`;
  return await parseOrThrowRequest(url);
};

export const fetchProjectIssueComments = async (projectId, issueId) => {
  const url = `/api/project/${projectId}/issue/${issueId}/comment`;
  return await parseOrThrowRequest(url);
};

export const createProjectIssueComment = async (projectId, issueId, contents) => {
  const url = `/api/project/${projectId}/issue/${issueId}/comment`;
  return await post(url, { contents: contents });
};

export const resolveProjectIssue = async (projectId, issueId) => {
  const url = `/api/project/${projectId}/issue/${issueId}/resolve`;
  return await post(url);
};

export const fetchProjectServiceLabels = async (projectId) => {
  let url = `/api/project/${projectId}/service-labels`;
  return await parseOrThrowRequest(url);
};

export const fetchUserSpecifiedServiceLabels = async (project) => {
  let url = `/api/project/${project.uuid}/user-labels`;
  return await parseOrThrowRequest(url);
};

export const fetchApplicationBuilds = async (applicationUuid) => {
  const url = `/api/application/${applicationUuid}/builds`;
  return await parseOrThrowRequest(url);
};

/** Data dashboard */
export const fetchCurrentVolumes = async (applicationId) => {
  const url = `/api/application/${applicationId}/current-volumes`;
  const res = await fetch(url);
  if (!res.ok) throw await responseErrorText(res);
  else return await res.json();
};

export const fetchAppBuildSnapshotSet = async (appBuildSnapshotSetUuid) => {
  const url = `/api/app-build-snapshot-set/${appBuildSnapshotSetUuid}`;
  const res = await fetch(url);
  if (!res.ok) throw await responseErrorText(res);
  else return await res.json();
};

/** Full compose file for a given repo's branch */
export const fetchRawCompose = async (orgName, repoName, branchName) => {
  const url = `/api/repo/${orgName}/${repoName}/${branchName}/compose`;
  return await parseOrThrowRequest(url);
};

/** open PR for new compose file */
export const openNewComposePR = async (projectId) => {
  const url = `/api/project/${projectId}/open-compose-pr`;
  return await post(url, {});
};

/** open PR for new compose file for applications*/
export const openNewComposeAppPr = async (appId) => {
  const url = `/api/application/${appId}/open-compose-pr`;
  return await post(url, {});
};

export const fetchApplicationSet = async (uuid) => {
  const url = `/api/application-set/${uuid}`;
  return await parseOrThrowRequest(url);
};

export const fetchApplicationSetApplications = async (uuid) => {
  const url = `/api/application-set/${uuid}/applications`;
  return await parseOrThrowRequest(url);
};

export const fetchApplicationSetSnapshots = async (uuid) => {
  const url = `/api/application-set/${uuid}/snapshot-sets`;
  return await parseOrThrowRequest(url);
};

export const fetchApplicationAsOauth = async (
  applicationId,
  { domain = window.location.href, userToken = "", shipyardToken = "" } = {}
) => {
  const url = new URL(`/api/oauth/application/${applicationId}`, domain);
  if (shipyardToken) url.searchParams.set("shipyard_token", shipyardToken);

  let headers = {};
  if (userToken) headers = { "x-visitor-token": userToken };
  return await parseOrThrowRequest(url.toString(), { headers: headers });
};

/** Application overview and enabled service names */
export const fetchApplicationConfiguration = async (applicationId) => {
  const url = `/api/application/${applicationId}/configure`;
  return await parseOrThrowRequest(url);
};

export const fetchApplicationSettings = async (applicationId) => {
  const url = `/api/application/${applicationId}/settings`;
  return await parseOrThrowRequest(url);
};

export const createEnvironmentFromPullRequest = async (projectUuid, prNumber) =>
  await post(`/api/project/${projectUuid}/open-pr-from-number/${prNumber}`);

export const fetchApplicationGithubLabels = async (applicationId) => {
  const url = `/api/application/${applicationId}/github-labels`;
  return await parseOrThrowRequest(url);
};

export const fetchSiblingApplications = async (applicationId) => {
  const url = `/api/application/${applicationId}/sibling-applications`;
  return await parseOrThrowRequest(url);
};

export const fetchApplicationBuildTimes = async (uuid, params = {}) => {
  const url = new URL(`/api/application/${uuid}/build-times`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url);
};

export const fetchApplicationPrActivity = async (uuid, params = {}) => {
  const url = new URL(`/api/application/${uuid}/pr-activity`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url);
};

export const fetchBuild = async (buildId, params = {}) => {
  const url = new URL(`/api/build/${buildId}`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url);
};

export const fetchBuildProject = async (buildId) => {
  const url = `/api/build/${buildId}/project`;
  return await parseOrThrowRequest(url);
};

/** Pod timeline for a single build service */
export const fetchBuildServicePodTimelines = async (serviceId, buildId) => {
  const url = `/api/build/${buildId}/service/${serviceId}/pod-timeline`;
  return await parseOrThrowRequest(url);
};

/** Run log for a single build service */
export const fetchBuildServiceRunLogs = async (serviceId, buildId) => {
  const url = `/api/build/${buildId}/service/${serviceId}/run-logs`;
  return await parseOrThrowRequest(url);
};

export const updateBuildCompose = async (build, compose, dockerfiles) =>
  await post(`/api/build/${build.uuid}/compose-update`, { compose, dockerfiles });

export const openBuildPullRequest = async (build) =>
  await post(`/api/build/${build.uuid}/open-pull-request`);

export const addProjectBranchDenyPattern = async (project, newPattern) =>
  await post(`/api/project/${project.uuid}/branch-deny-pattern`, { newPattern });

/** Environment runtime variables */
export const fetchSecrets = async (originType, originId) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to fetch secrets for ${originType}`;
  const url = `/api/secret/${originType}/${originId}`;
  return await parseOrThrowRequest(url);
};

export const createSecret = async (originType, originId, { name, value, hidden, services }) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to update secret for ${originType}`;
  const url = `/api/secret/${originType}/${originId}`;
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, value, hidden, services }),
  });
  if (!res.ok) throw await responseErrorText(res);
  if (res.status === 200) return await res.json();
  return await res.json();
};

export const updateSecret = async (
  originType,
  originId,
  { name, value, secretUuid, services, hidden }
) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to update secret for ${originType}`;
  const url = `/api/secret/${originType}/${originId}/secrets/${secretUuid}`;
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, value, secretUuid, services, hidden }),
  });
  if (!res.ok) throw await responseErrorText(res);
  if (res.status === 200) return;
  return await res.json();
};

export const batchUpdateSecrets = async (originType, originId, { secretsString }) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to update secrets for ${originType}`;
  return await post(`/api/secret/${originType}/${originId}/batch`, {
    environment_variables: secretsString,
  });
};

// /api/secret/<string:origin_type>/<string:uuid>/secrets/<string:secret_uuid>
export const deleteSecret = async (originType, originId, uuid) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to delete secret for ${originType}`;
  await deleteRequest(`/api/secret/${originType}/${originId}/secrets/${uuid}`);
};

export const preprocessBatchSecrets = async ({ secretsString }) => {
  return await post(`/api/secret/preprocess`, {
    environment_variables: secretsString,
  });
};

/** Environment build-time variables */
export const fetchBuildArgs = async (originType, originId) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to fetch build args for ${originType}`;
  const url = `/api/build-arg/${originType}/${originId}`;
  return await parseOrThrowRequest(url);
};

export const createBuildArg = async (originType, originId, { name, value, hidden, services }) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to update secret for ${originType}`;
  const url = `/api/build_arg/${originType}/${originId}`;
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, value, hidden, services }),
  });
  if (!res.ok) throw await responseErrorText(res);
  if (res.status === 200) return await res.json();
  return await res.json();
};

export const updateBuildArg = async (
  originType,
  originId,
  { name, value, secretUuid, services, hidden }
) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to update secret for ${originType}`;
  const url = `/api/build_arg/${originType}/${originId}/build_args/${secretUuid}`;
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, value, secretUuid, services, hidden }),
  });
  if (!res.ok) throw await responseErrorText(res);
  if (res.status === 200) return;
  return await res.json();
};

export const deleteBuildArg = async (originType, originId, uuid) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to delete build arg for ${originType}`;
  return await deleteRequest(`/api/build_arg/${originType}/${originId}/build_args/${uuid}`, uuid);
};

export const batchUpdateBuildArgs = async (originType, originId, { secretsString }) => {
  if (!ALLOWED_ORIGINS.includes(originType)) throw `Unable to update secrets for ${originType}`;
  return await post(`/api/build-arg/${originType}/${originId}/batch`, {
    build_args: secretsString,
  });
};

export const fetchSLIData = async (params = {}) => {
  const url = new URL(`/api/telemetry/sli`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url);
};

export const fetchOrgNames = async () => {
  const url = `/api/telemetry/org-names`;
  return await parseOrThrowRequest(url);
};

// fixme: may be a graphql endpoint?
export const telemetryFetchActiveBuilds = async () => {
  const url = `/api/telemetry/active-builds`;
  return await parseOrThrowRequest(url);
};
export const telemetryFetchPreviousBuilds = async () => {
  const url = "/api/telemetry/previous-builds";
  return await parseOrThrowRequest(url);
};

export const telemetryFilteredBuilds = async (filters) => {
  const url = "/api/telemetry/build/filter";
  return await post(url, { filters });
};

export const telemetryFetchEventLogs = async (params = {}) => {
  const url = new URL(`/api/telemetry/events/logs`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url.toString());
};

export const telemetryFetchCluster = async (clusterId, params = {}) => {
  const url = new URL(`/api/telemetry/cluster/${clusterId}`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url.toString());
};

export const telemetryFetchBuildLogs = async (buildId, params = {}) => {
  const url = new URL(`/api/telemetry/application-build/${buildId}/logs`, window.location.href);
  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
  return await parseOrThrowRequest(url.toString());
};

export const telemetryAdminAction = async (action, toggleType = null, params = {}) => {
  let url = `/api/telemetry/admin-action/${action}/`;
  if (toggleType) {
    url = url + `${toggleType}`;
  }
  return await post(url, params);
};

export const telemetryConfigureOrgCluster = async (
  orgName,
  { maxConcurrentBuilds, preemptiblePoolSize, preemptiblePoolMachineType, nfsDiskSizeGb }
) => {
  const url = `/api/telemetry/org/${orgName}/org-cluster/configure`;
  return await post(url, {
    build_pool_size: maxConcurrentBuilds,
    preemptible_pool_size: preemptiblePoolSize,
    preemptible_pool_machine_type: preemptiblePoolMachineType,
    nfs_disk_size_gb: nfsDiskSizeGb,
  });
};

export const telemetryConfigureOrgClusterBuildPoolSize = async (orgName, { value }) => {
  const url = `/api/telemetry/org/${orgName}/org-cluster/configure/build-pool-size`;
  return await post(url, {
    build_pool_size: value,
  });
};

export const telemetryConfigureOrgClusterPreemptiblePoolSize = async (orgName, { value }) => {
  const url = `/api/telemetry/org/${orgName}/org-cluster/configure/preemptible-pool-size`;
  return await post(url, {
    preemptible_pool_size: value,
  });
};

export const telemetryConfigureOrgClusterPreemptiblePoolMachineType = async (
  orgName,
  { value }
) => {
  const url = `/api/telemetry/org/${orgName}/org-cluster/configure/preemptible-pool-machine-type`;
  return await post(url, {
    preemptible_pool_machine_type: value,
  });
};

export const telemetryConfigureOrgClusterBuildPoolMachineType = async (orgName, { value }) => {
  const url = `/api/telemetry/org/${orgName}/org-cluster/configure/build-pool-machine-type`;
  return await post(url, {
    build_pool_machine_type: value,
  });
};

export const telemetryConfigureOrgClusterNfsDiskSize = async (orgName, { value }) => {
  const url = `/api/telemetry/org/${orgName}/org-cluster/configure/nfs-disk-size`;
  return await post(url, {
    nfs_disk_size_gb: value,
  });
};

export const createCluster = async ({
  clusterName,
  clusterType,
  clusterProvider,
  clusterDiskSize,
  provisionCluster,
  provisionNodePools,
  clusterNodePools,
  clusterCredentials,
  clusterK8sVersion,
}) => {
  const url = `/api/cluster`;
  return await post(url, {
    provider: clusterProvider,
    name: clusterName,
    type: clusterType,
    nfs_disk_size_gb: clusterDiskSize,
    provision_node_pools: provisionNodePools,
    provision: provisionCluster,
    node_pool_configs: clusterNodePools,
    cluster_credentials: clusterCredentials,
    kubernetes_version: clusterK8sVersion,
  });
};

export const provisionCluster = async (clusterUuid, { forceProvision, provisionNodePools }) => {
  const url = `/api/cluster/${clusterUuid}/provision`;
  return await post(url, {
    force: forceProvision,
    provision_node_pools: provisionNodePools,
  });
};

export const deprovisionCluster = async (
  clusterUuid,
  { teardownDataVolumes, deleteResourceGroup }
) => {
  const url = `/api/cluster/${clusterUuid}/deprovision`;
  return await post(url, {
    teardown_data_volumes: teardownDataVolumes,
    delete_resource_group: deleteResourceGroup,
  });
};

export const provisionNodePool = async (clusterUuid, nodePoolName) => {
  const url = `/api/cluster/${clusterUuid}/node-pool/${nodePoolName}/provision`;
  return await post(url);
};

export const deprovisionNodePool = async (clusterUuid, nodePoolName) => {
  const url = `/api/cluster/${clusterUuid}/node-pool/${nodePoolName}/deprovision`;
  return await post(url);
};
