var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
var ShipyardBoat = forwardRef(function (_a, ref) {
    var _b = _a.color, color = _b === void 0 ? "currentColor" : _b, _c = _a.size, size = _c === void 0 ? 15 : _c, rest = __rest(_a, ["color", "size"]);
    return (React.createElement("svg", __assign({ ref: ref, xmlns: "http://www.w3.org/2000/svg", width: size, height: size, viewBox: "0 0 16 15", fill: color, stroke: "none" }, rest),
        React.createElement("g", { clipPath: "url(#clip0)", transform: "translate(0, -1.25)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.00008 2.50003C6.00008 2.22389 6.22393 2.00003 6.50008 2.00003H9.50008C9.77622 2.00003 10.0001 2.22389 10.0001 2.50003V4.00003H14.0001V5.00003H13.0001V7.00003H16.0001V7.50003C16.0001 8.2646 15.8075 9.38257 15.2048 10.7004C14.8864 11.3966 14.2848 12.1801 13.7914 12.7653C13.5398 13.0637 13.3071 13.3213 13.1372 13.5045C13.0521 13.5961 12.9825 13.6694 12.9339 13.7201C12.9096 13.7454 12.8905 13.7651 12.8773 13.7787L12.862 13.7943L12.8579 13.7986L12.8567 13.7998C12.8567 13.7998 12.8561 13.8004 12.5001 13.4493L12.8567 13.7998L12.505 14.1564L11.793 13.4542L12.1447 13.0975L12.1478 13.0944L12.1607 13.0812C12.1723 13.0692 12.1898 13.0513 12.2124 13.0277C12.2575 12.9806 12.3233 12.9114 12.4042 12.8243C12.5661 12.6497 12.7879 12.4042 13.0269 12.1208C13.5145 11.5424 14.0379 10.8474 14.2954 10.2845C14.7124 9.37259 14.9025 8.5898 14.9698 8.00003H1.03441C1.10749 8.57225 1.30593 9.33326 1.71114 10.299C1.95538 10.8811 2.47314 11.5704 2.96177 12.1394C3.20088 12.4178 3.42363 12.6567 3.58649 12.8259C3.66781 12.9103 3.7339 12.9771 3.77928 13.0224C3.80195 13.045 3.81943 13.0623 3.83102 13.0736L3.8439 13.0862L3.84686 13.0891L4.20708 13.437L3.5124 14.1563L3.15274 13.809L3.50008 13.4493C3.15274 13.809 3.15274 13.809 3.15274 13.809L3.15083 13.8071L3.14648 13.8029L3.13074 13.7875C3.11721 13.7742 3.09769 13.755 3.07288 13.7302C3.02329 13.6807 2.95249 13.6092 2.86607 13.5194C2.69348 13.3401 2.45742 13.087 2.20315 12.7909C1.70504 12.2109 1.0978 11.4218 0.789017 10.6859C0.22026 9.33037 7.67708e-05 8.274 7.67708e-05 7.50003V7.00003H3.00008V5.00003H2.00008V4.00003H6.00008V2.50003ZM7.00008 4.00003H9.00008V3.00003H7.00008V4.00003ZM4.00008 5.00003V7.00003H12.0001V5.00003H4.00008Z" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M-0.478882 15.2H0.0211182C0.392351 15.2 0.700141 15.0657 0.952772 14.9034C1.16673 14.766 1.36802 14.5894 1.53987 14.4387C1.56211 14.4192 1.58386 14.4001 1.60508 14.3816C2.00937 14.0291 2.31055 13.8095 2.71556 13.8095C3.15218 13.8095 3.3501 14.0035 3.67423 14.3476C3.67997 14.3537 3.68575 14.3598 3.69157 14.366C4.01952 14.7145 4.47649 15.2 5.35445 15.2C6.22513 15.2 6.68963 14.7221 7.0254 14.3766C7.03296 14.3688 7.04045 14.3611 7.04789 14.3535C7.38126 14.0109 7.59421 13.8095 8.04829 13.8095C8.50295 13.8095 8.72243 14.0129 9.04261 14.3494C9.05668 14.3642 9.07101 14.3793 9.08563 14.3948C9.39932 14.7264 9.84725 15.2 10.66 15.2C11.4727 15.2 11.9387 14.7271 12.267 14.394C12.2795 14.3812 12.2919 14.3687 12.3041 14.3564C12.6469 14.0098 12.8702 13.8095 13.2953 13.8095C13.6119 13.8095 13.7605 13.9593 14.1161 14.3177C14.1289 14.3306 14.1419 14.3437 14.1552 14.3571C14.3334 14.5365 14.5606 14.7544 14.8658 14.9214C15.1797 15.0931 15.554 15.2 16.0211 15.2H16.5211V14.2H16.0211C15.717 14.2 15.5084 14.1331 15.3458 14.0441C15.1746 13.9504 15.0318 13.8206 14.8647 13.6524C14.839 13.6265 14.8124 13.5991 14.7846 13.5705C14.4813 13.2581 14.0457 12.8095 13.2953 12.8095C12.4275 12.8095 11.9444 13.298 11.6 13.6462L11.5931 13.6531C11.2356 14.0146 11.0374 14.2 10.66 14.2C10.2895 14.2 10.1128 14.0235 9.76708 13.6601C9.42579 13.3014 8.94998 12.8095 8.04829 12.8095C7.15489 12.8095 6.68379 13.2937 6.34139 13.6456L6.33125 13.656C5.99218 14.0044 5.78903 14.2 5.35445 14.2C4.92313 14.2 4.72782 14.0077 4.40213 13.6619C4.39747 13.657 4.39278 13.652 4.38806 13.647C4.05833 13.2967 3.59964 12.8095 2.71556 12.8095C1.89836 12.8095 1.34095 13.2852 0.94789 13.6279C0.925493 13.6474 0.903709 13.6665 0.882465 13.685C0.701195 13.8435 0.559282 13.9676 0.412304 14.062C0.259079 14.1605 0.138522 14.2 0.0211182 14.2H-0.478882V15.2Z" }))));
});
ShipyardBoat.displayName = "ShipyardBoat";
export default ShipyardBoat;
