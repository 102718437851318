var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Check, Pause, X } from "lucide-react";
import React, { forwardRef, useRef, useState } from "react";
import { Overlay, Popover, Tooltip } from "react-bootstrap";
import { ShipyardLink } from "./constants";
import { LoadingSpinner } from "./icons/Loader";
import { buildFailureLabel, readableBuildFailure, buildStatusToHighLevelStatus, highLevelStatusToColor, buildStatusLabels, } from "./utils/build";
import VisitButton from "./VisitButton";
/**
 * A Generic Badge
 */
export var Badge = forwardRef(function (props, ref) {
    var children = props.children, _a = props.minimal, minimal = _a === void 0 ? false : _a, _b = props.bgColor, bgColor = _b === void 0 ? "primary" : _b, _c = props.textColor, textColor = _c === void 0 ? "light" : _c, rest = __rest(props, ["children", "minimal", "bgColor", "textColor"]);
    return (React.createElement("div", __assign({ ref: ref }, rest, { style: { borderRadius: "14px", minWidth: minimal ? "4.5rem" : undefined }, className: "status-indicator flex-grow-0 justify-content-center text-nowrap align-items-center text-capitalize text-".concat(textColor, " bg-").concat(bgColor, " px-3 d-flex align-items-center flex-gap-6px"), "data-cy": "status-indicator" }), children));
});
Badge.displayName = "Badge";
var isBuild = function (b) {
    return b.status !== undefined;
};
export var StatusIndicator = function (_a) {
    var build = _a.build, _b = _a.deployable, deployable = _b === void 0 ? null : _b, _c = _a.minimal, minimal = _c === void 0 ? false : _c, _d = _a.showTooltip, showTooltip = _d === void 0 ? true : _d;
    // ApplicationBuild has cumulative_status not status
    var status;
    if (isBuild(build)) {
        status = build.status;
    }
    else {
        status = build.cumulative_status;
    }
    var badgeRef = useRef(null);
    var _e = useState(false), show = _e[0], toggleTooltip = _e[1];
    var _f = highLevelStatusToColor(buildStatusToHighLevelStatus(build)), backgroundColor = _f.backgroundColor, color = _f.color;
    var contents;
    // Special labels for some build failures
    if (build.failed && build.failure_reason === "TOO_MANY_BUILDS") {
        contents = buildFailureLabel["TOO_MANY_BUILDS"];
    }
    else if (build.failed && build.failure_reason === "HEALTH_CHECK_FAILED") {
        contents = buildFailureLabel["HEALTH_CHECK_FAILED"];
    }
    else {
        contents = buildStatusLabels[status];
    }
    // Show spinner if not yet completed
    if (!build.running && !build.stopped) {
        contents = (React.createElement(React.Fragment, null,
            React.createElement(LoadingSpinner, { style: { marginLeft: "-1px", height: "12px", flexShrink: 0 }, fill: "currentColor" }),
            React.createElement("span", null, contents)));
    }
    var overlay;
    if (build.failed && build.failure_reason) {
        overlay = (React.createElement(Overlay, { target: badgeRef.current, show: show && showTooltip, placement: "right" },
            React.createElement(Popover, { id: "build-status-overlay", "data-cy": "build-status-overlay" },
                React.createElement(Popover.Content, null,
                    React.createElement("div", { className: "table-responsive" },
                        React.createElement("table", { className: "table card-table table-striped table-vcenter" },
                            React.createElement("tbody", null,
                                React.createElement("tr", null,
                                    React.createElement("td", null, readableBuildFailure(build.failure_reason))))))))));
    }
    else if (build.running && (deployable === null || deployable === void 0 ? void 0 : deployable.reason_not_visitable)) {
        overlay = (React.createElement(Overlay, { target: badgeRef.current, show: show, placement: "right" },
            React.createElement(Popover, { id: "build-status-overlay", "data-cy": "build-status-overlay" },
                React.createElement(Popover.Content, null,
                    React.createElement("strong", null, deployable === null || deployable === void 0 ? void 0 : deployable.reason_not_visitable)))));
    }
    var tooltipAnchorProps = {
        ref: badgeRef,
        onMouseEnter: function () { return toggleTooltip(true); },
        onMouseLeave: function () { return toggleTooltip(false); },
    };
    // Show an abbreviated status indicator if `minimal` and build is at specific points
    if (minimal && (build.failed || build.running || build.retired)) {
        var icon = void 0;
        if (build.failed)
            icon = (React.createElement(X, __assign({ style: { strokeWidth: 4, height: "1.25rem" }, color: "red" }, tooltipAnchorProps)));
        else if (build.running)
            icon = (React.createElement(Check, __assign({ style: { strokeWidth: 4, height: "1.25rem" }, color: "green" }, tooltipAnchorProps)));
        else
            icon = (React.createElement(Pause, __assign({ style: { strokeWidth: 2, height: "1.25rem" }, color: "gray" }, tooltipAnchorProps)));
        return (React.createElement(React.Fragment, null,
            icon,
            overlay));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Badge, __assign({ textColor: color, bgColor: backgroundColor }, tooltipAnchorProps), contents),
        overlay));
};
/** Given a project, returns either a non-clickable status indicator, or a "visit" button */
export var VisitButtonOrStatusIndicator = function (_a) {
    var build = _a.build, canBeVisited = _a.canBeVisited, url = _a.url;
    if (canBeVisited && url)
        return React.createElement(VisitButton, { url: url });
    return React.createElement(StatusIndicator, { build: build });
};
export var InfoPopup = function (_a) {
    var children = _a.children, content = _a.content, style = _a.style;
    var contentRef = useRef(null);
    var _b = useState(false), show = _b[0], toggleShow = _b[1];
    return (React.createElement("span", { style: style, ref: contentRef, onMouseEnter: function () { return toggleShow(true); }, onMouseLeave: function () { return toggleShow(false); } },
        children && React.createElement(React.Fragment, null,
            children,
            " "),
        React.createElement("i", { className: "fa fa-info-circle" }),
        React.createElement(Overlay, { target: contentRef.current, show: show, placement: "top" },
            React.createElement(Tooltip, { id: "info-tooltip", "data-cy": "info-tooltip" }, content))));
};
/** Icon for github avatar, with default avatar if no url given.
 *  If a login is given, it will be shown in a tooltip.
 */
export var GithubAvatarIcon = function (_a) {
    var githubAvatarUrl = _a.githubAvatarUrl, _b = _a.githubLogin, githubLogin = _b === void 0 ? "" : _b, _c = _a.showTooltip, showTooltip = _c === void 0 ? true : _c;
    var ghRef = useRef(null);
    var _d = useState(false), show = _d[0], setShow = _d[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "avatar avatar-xsm", ref: ghRef, style: {
                backgroundImage: githubAvatarUrl
                    ? "url(".concat(githubAvatarUrl, ")")
                    : "url(".concat(ShipyardLink.CDN_URL, " + \"/imgs/glider.jpg\")"),
            }, onMouseEnter: function () { return setShow(true); }, onMouseLeave: function () { return setShow(false); } }),
        React.createElement(Overlay, { target: ghRef.current, show: showTooltip && !!githubLogin && show, placement: "top" },
            React.createElement(Tooltip, { id: "github-login-tooltip", "data-cy": "github-login-tooltip" }, githubLogin))));
};
